.form-control {
    background: transparent !important;
    color: #F96C03;
}

.form-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

Label {
    margin-right: 10px;
}

.upload-file {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

Button {
    background: #F96C03 !important;
    /* border-radius: 0px !important; */
    border: 0px !important;
}

.form-label {
    color: #070707;

}

.form-control {
    /* border: 2px solid #F96C03; */
}

.form-control:focus {

    border-color: #F96C03;
    outline: 0;
    box-shadow: 0 0 0 0 #F96C03
}